<template>
  <div>
    <div class="mid_box">
      <img
        style="width: 15px"
        src="../../assets/images/Examine_img/back.png"
        alt=""
        @click="toBack"
      />
      <span style="margin-right: 15px" @click="toBack">返回</span>
      <el-button
        style="margin-left: 10px"
        type="primary"
        class="edit"
        @click="getPdf()"
        >打印检查表</el-button
      >
      <el-button
        style="margin-left: 10px"
        type="primary"
        class="edit"
        @click="exportExcel"
        >导出检查表</el-button
      >
    </div>

    <el-table
      :data="tableData.slice(start, end)"
      tooltip-effect="dark"
      style="width: 99%"
      :key="itemKey"
      :height="elementHeight"
      highlight-current-row
      border
      id="pdfDom"
      @row-click="handleRowClick"
      :header-cell-style="{
        background: '#EDF0F7',
        color: '#000000',
        border: '1px solid #DCDCDC',
      }"
    >
      <el-table-column type="index" label="序号" width="80"> </el-table-column>
      <el-table-column prop="fileName" label="文件名称" width="230">
      </el-table-column>
      <el-table-column prop="question" label="检查内容"> </el-table-column>
      <el-table-column
        prop="auditResult"
        label="审核结果  "
        align="center"
        width="300"
      >
        <template slot-scope="scope">
          <div class="auditResult_box" v-if="scope.row.mixAuditType != 3 && scope.row.mixAuditType != 4 && !$route.query.show7STable">
            <span v-if="scope.row.auditResult == 1">符合</span>
            <span v-if="scope.row.auditResult == 0">不符合</span>
            <span v-if="scope.row.auditResult == 2">纠正</span>
            <span v-if="scope.row.auditResult == 3">改进</span>
            <span v-if="scope.row.auditResult == 4">不适用</span>
          </div>

          <!-- 内外审  checkStatus：0自查，1自纠-->
          <div class="auditResult_box" v-if="scope.row.mixAuditType == 3">
            <div :class="{ auditactive: scope.row.auditResult == 1 }">
              <span v-if="scope.row.checkStatus == 0">是</span>
              <span v-else>已符合</span>
            </div>
            <div :class="{ auditactive: scope.row.auditResult == 0 }">
              <span v-if="scope.row.checkStatus == 0">否</span>
              <span v-else>是</span>
            </div>
            <div v-if="scope.row.checkStatus == 1" :class="{ auditactive: scope.row.auditResult == 3 }">
              不合适
            </div>
          </div>

          <div class="auditResult_box" v-if="$route.query.show7STable && isShow">
            <!-- <div @click="changeResult(scope.row, 1)" :class="{ auditactive: scope.row.auditResult == 1 }">5分(符合)</div>
            <div @click="changeResult(scope.row, 3)" :class="{ auditactive: scope.row.auditResult == 3 }">3分(改进)</div>
            <div @click="changeResult(scope.row, 0)" :class="{ auditactive: scope.row.auditResult == 0 }">1分(不符)</div>
            <div @click="changeResult(scope.row, 4)" :class="{ auditactive: scope.row.auditResult === 4 }">0分(不适用)</div> -->
            
              <div :class="{ auditactive: scope.row.auditResult === 5}">5分</div>
              <div :class="{ auditactive: scope.row.auditResult === 4}">4分</div>
              <div :class="{ auditactive: scope.row.auditResult === 3}">3分</div>
              <div :class="{ auditactive: scope.row.auditResult === 2}">2分</div>
              <div :class="{ auditactive: scope.row.auditResult === 1}">1分</div>
              <div :class="{ auditactive: scope.row.auditResult === 0 && scope.row.isSuitable === 0}">0分</div>
              <div :class="{ auditactive: scope.row.isSuitable === 1 && scope.row.auditResult === 0 }">不适用</div>
          </div>
          <div class="auditResult_box" v-if="$route.query.show7STable && !isShow">
              <div v-if="scope.row.auditResult === 5">5分</div>
              <div v-if="scope.row.auditResult === 4">4分</div>
              <div v-if="scope.row.auditResult === 3">3分</div>
              <div v-if="scope.row.auditResult === 2">2分</div>
              <div v-if="scope.row.auditResult === 1">1分</div>
              <div v-if="scope.row.auditResult === 0 && scope.row.isSuitable === 0">0分</div>
              <div v-if="scope.row.auditResult === 0 && scope.row.isSuitable === 1">不适用</div>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="imagesCount"
        label="照片列表"
        align="center"
        width="190
        "
        disabled
      >
        <template slot-scope="scope">
          <div class="img_box" @click="openImg(scope.row)">
            <img src="../../assets/images/Examine_img/add.png" alt="" />
            <span style="margin-right: 50%">{{ scope.row.imagesCount }}</span>
            <img src="../../assets/images/Examine_img/picture.png" alt="" />
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="lawAsk" label="审核记录（双击列表）" width="200">
        <template slot-scope="scope">
          <span>{{ scope.row.lawAsk }}</span>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page.sync="currentPage"
      :page-sizes="[10, 20, 50]"
      :page-size="10"
      layout="total, sizes, prev, pager, next, jumper"
      :total="tableData.length"
    >
    </el-pagination>
    <el-dialog
      title="查看/添加图片"
      :visible.sync="dialogVisibleimg"
      width="40%"
    >
      <!-- <div>上传图片</div>
      <el-upload
        action=" https://jsonplaceholder.typicode.com/posts/"
        list-type="picture-card"
        ref="upload"
        :on-preview="handlePictureCardPreview"
        :http-request="uploadFile"
        :headers="token"
      >
        <i class="el-icon-plus"></i>
      </el-upload> -->
      <div>已上传图片</div>
      <span class="ImgDel" v-for="(item, index) in srcList" :key="item">
        <el-image
          style="width: 100px; height: 100px"
          :src="item"
          :preview-src-list="srcList"
          @mouseover="overimg(index)"
        >
        </el-image>

        <i
          class="el-icon-delete-solid"
          v-show="index === Curindex"
          @click="delImg(item)"
        ></i>
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisibleimg = false"
          >确 定</el-button
        >
        <el-button @click="dialogVisibleimg = false">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { get } from '../../api/http'
import { exportExcel } from '../../modules/ExportExcel'

export default {
  data() {
    return {
      htmlTitle: '文件检查',
      elementHeight: 0,
      tableData: [],
      tableRowIndex: null,
      start: 0,
      end: 10,
      curSize: 10,
      currentPage: '',
      Cid: '',
      srcList: [],
      dialogVisibleimg: false,
      mixType:{},
      isShow:true,
    }
  },
  mounted() {
    this.getTdata()
    this.getElementHeight()
  },
  methods: {
    getElementHeight() {
      this.$nextTick(() => {
        this.elementHeight =
          window.innerHeight -
          (document.getElementById('pdfDom').offsetTop + 60 + 52)
      })
    },
    getTdata() {
      if (this.$route.query.AuditId) {
        get(
          '/api/AreaAuditList/GetByAuditId?AuditId=' + this.$route.query.AuditId
        ).then((res) => {
          this.tableData = res.data
        })
        return
      }
      get('/api/MixAuditList/GetById?AuditId=' + this.$route.query.Id).then(
        (res) => {
          this.tableData = res.data
        }
      )
    },
    handleRowClick(row) {
      this.tableRowIndex = row.index
    },
    tableRowClassName({ row, rowIndex }) {
      row.index = rowIndex
    },
    selectedstyle({ rowIndex }) {
      if (this.tableRowIndex == rowIndex) {
        return {
          'background-color': '#E2E8FA !important',
          'font-weight': 'bold !important',
          color: '#555555',
        }
      }
    },
    toBack() {
      this.$router.go(-1)
    },
    handleSizeChange(e) {
      this.curSize = e
      this.start = (this.currentPage - 1) * this.curSize
      this.end = this.start + this.curSize
    },
    handleCurrentChange(e) {
      this.start = (e - 1) * this.curSize
      this.end = this.start + this.curSize
    },
    exportExcel() {
      this.isShow = false;
      this.$nextTick(() =>{
        exportExcel('#pdfDom', '文件检查.xlsx')
        this.isShow = true;
      })
    },
    openImg(e) {
      this.Cid = e.id
      this.dialogVisibleimg = true
      this.getimg()
    },
    getimg() {
      this.srcList = []
      if (this.$route.query.AuditId) {
        get('/api/AreaAuditListImage/' + this.Cid).then((res) => {
          this.imgList = res.value
          res.value.forEach((element) => {
            this.srcList.push(element.picNameAndAddr)
          })
        })
        return
      }
      get('/api/MixAuditListImage/' + this.Cid).then((res) => {
        this.imgList = res.value
        res.value.forEach((element) => {
          this.srcList.push(element.picNameAndAddr)
        })
      })
    },
  },
}
</script>

<style scoped>
@import '../../assets/style/Examine_CSS.css';
</style>
